import styled from 'styled-components';

export const CategorySliderWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 2.5rem;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    width: 40px;
    pointer-events: none;
    background: linear-gradient(to right, rgba(253, 251, 249, 0), rgba(253, 251, 249, 1));
  }
`;
