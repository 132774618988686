import { Children, FC, ElementType, ReactNode } from 'react';

interface AccordionHeadingWrapperProps {
  children: ReactNode;
  Wrapper?: ElementType;
}

export const AccordionHeadingWrapper: FC<AccordionHeadingWrapperProps> = ({
  children,
  Wrapper,
}) => {
  if (!Children.count(children)) return <></>;
  if (!Wrapper) return <>{children}</>;
  return <Wrapper>{children}</Wrapper>;
};
