import { NextSeo } from 'next-seo';
import React from 'react';
import { FACEBOOK_ID } from '../../config/config';

type IDefaultMetadata = {
  title?: string;
  description?: string;
};

export function DefaultMetadata({ title, description }: IDefaultMetadata): JSX.Element {
  return <NextSeo title={title} description={description} />;
}

DefaultMetadata.defaultProps = {
  title: '',
  description: '',
};

type IPageMetadata = {
  locale: string;
  metadata: {
    pageName: string;
    theme?: string;
    description?: string;
    openGraphType?: string;
    images: {
      url: string;
      width: number;
      height: number;
      altText?: string;
    }[];
  };
};

export function PageMetaData({ metadata, locale }: IPageMetadata) {
  const transformImages = (
    imageArray: {
      url: string;
      width: number;
      height: number;
      alText?: string;
    }[],
  ) =>
    imageArray.map((image) => {
      const transformedImage = {
        url: image.url,
        height: image.height,
        width: image.width,
        alt: image.alText,
      };
      return transformedImage;
    });

  return (
    <NextSeo
      title={metadata?.pageName ? metadata?.pageName : ''}
      description={metadata?.description || ''}
      openGraph={{
        title: metadata?.pageName || '',
        description: metadata?.description || '',
        locale,
        ...(metadata?.images && { images: transformImages(metadata?.images) }),
      }}
      additionalMetaTags={[
        {
          name: 'facebook-domain-verification',
          content: FACEBOOK_ID
        }
      ]}
    />
  );
}

export function NoIndexedMetadata(): JSX.Element {
  return <NextSeo noindex />;
}
