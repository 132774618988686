import React, { ReactNode } from 'react';
import tw from 'tailwind-styled-components';

// A simple grid system, built with Tailwind's CSS Grid classes.

const GridContainer = tw.div<{ $skipPaddingOnMobile?: boolean }>`
  ${(p) => (p.$skipPaddingOnMobile ? 'px-0' : 'px-small')}
  2xl:px-medium
  3xl:px-xlarge
  w-full
`;

export const Grid = ({
  children,
  skipPaddingOnMobile,
}: {
  children: ReactNode;
  skipPaddingOnMobile?: boolean;
}): JSX.Element => (
  <GridContainer $skipPaddingOnMobile={skipPaddingOnMobile}>{children}</GridContainer>
);

Grid.defaultProps = {
  skipPaddingOnMobile: false,
};

export const GridRow = ({
  columns = 1,
  children,
  className,
  tabletBreakpoint,
}: {
  columns?: number;
  children: ReactNode;
  className?: string;
  tabletBreakpoint?: boolean;
}): JSX.Element => {
  // This is the only method I've found that works.
  // Other methods with string interpolation cause the grid-cols
  // classes to be purged at build time.
  // See: https://tailwindcss.com/docs/optimizing-for-production
  // - CG

  let columnClass: string = '';
  switch (columns) {
    case 1:
      columnClass = 'lg:grid-cols-1';
      break;
    case 2:
      columnClass = 'lg:grid-cols-2 md:grid-cols-1';
      break;
    case 3:
      columnClass = 'lg:grid-cols-3';
      break;
    case 4:
      columnClass = 'lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1';
      break;
    case 5:
      columnClass = 'lg:grid-cols-5';
      break;
    case 6:
      columnClass = 'lg:grid-cols-6 md:grid-cols-3';
      break;
    case 7:
      columnClass = 'lg:grid-cols-7';
      break;
    case 8:
      columnClass = 'lg:grid-cols-8 md:grid-cols-4 sm:grid-cols-2';
      break;
    case 9:
      columnClass = 'lg:grid-cols-9 md:grid-cols-9';
      break;
    case 10:
      columnClass = 'lg:grid-cols-10 md:grid-cols-5';
      break;
    case 11:
      columnClass = 'lg:grid-cols-11';
      break;
    case 12:
      columnClass = 'lg:grid-cols-12 md:grid-cols-6 sm:grid-cols-3';
      break;
    case 16:
      if (!tabletBreakpoint) {
        columnClass = 'lg:grid-cols-16 md:grid-cols-16 sm:grid-cols-4';
      } else {
        columnClass = 'lg:grid-cols-16 md:grid-cols-8 sm:grid-cols-4';
      }
      break;
    default:
      columnClass = 'md:grid-cols-1';
      break;
  }

  const styles = `grid ${className} ${columnClass} gap-20 mb-5`;

  return <div className={styles}>{children}</div>;
};

GridRow.defaultProps = {
  columns: 1,
  className: '',
  tabletBreakpoint: false,
};

// For gridClass options, see tailwind:
// https://tailwindcss.com/docs/width
export const GridItem = ({
  colSpan = 1,
  colStart = 0,
  children,
  className,
}: {
  colSpan?: number;
  colStart?: number;
  children?: ReactNode;
  className?: string;
}): JSX.Element => {
  // This is the only method I've found that works.
  // Other methods with string interpolation cause the grid-cols
  // classes to be purged at build time.
  // See: https://tailwindcss.com/docs/optimizing-for-production
  // - CG
  let colSpanClass: string = '';
  switch (colSpan) {
    case 1:
      colSpanClass = 'col-span-1';
      break;
    case 2:
      colSpanClass = 'col-span-2 lg:col-span-2 md:col-span-1 sm:col-span-1';
      break;
    case 3:
      colSpanClass = 'col-span-3';
      break;
    case 4:
      colSpanClass = 'col-span-4';
      break;
    case 5:
      colSpanClass = 'lg:col-span-5 md:col-span-5 sm:col-span-4';
      break;
    case 6:
      colSpanClass = 'lg:col-span-6 md:col-span-6 sm:col-span-4';
      break;
    case 7:
      colSpanClass = 'lg:col-span-7 md:col-span-7 sm:col-span-4';
      break;
    case 8:
      colSpanClass = 'lg:col-span-8 md:col-span-8 sm:col-span-4';
      break;
    case 9:
      colSpanClass = 'lg:col-span-9 md:col-span-9 sm:col-span-4';
      break;
    case 10:
      colSpanClass = 'lg:col-span-10 md:col-span-10 sm:col-span-4';
      break;
    case 12:
      colSpanClass = 'lg:col-span-12 md:col-span-12 sm:col-span-4';
      break;
    case 14:
      colSpanClass = 'lg:col-span-14 md:col-span-14 sm:col-span-4';
      break;
    case 16:
      colSpanClass = 'lg:col-span-16 md:col-span-16 sm:col-span-4';
      break;
    default:
      colSpanClass = 'lg:col-span-1';
      break;
  }
  let colStartClass: string = '';
  switch (colStart) {
    case 1:
      colStartClass = 'lg:col-start-1 md:col-start-1';
      break;
    case 2:
      colStartClass = 'lg:col-start-2 md:col-start-2';
      break;
    case 3:
      colStartClass = 'lg:col-start-3 md:col-start-3';
      break;
    case 4:
      colStartClass = 'lg:col-start-4 md:col-start-4';
      break;
    case 5:
      colStartClass = 'lg:col-start-5 md:col-start-5';
      break;
    case 6:
      colStartClass = 'lg:col-start-6 md:col-start-6';
      break;
    case 7:
      colStartClass = 'lg:col-start-7 md:col-start-7';
      break;
    case 8:
      colStartClass = 'lg:col-start-8 md:col-start-8';
      break;
    case 9:
      colStartClass = 'lg:col-start-9 md:col-start-9';
      break;
    case 10:
      colStartClass = 'lg:col-start-10 md:col-start-10';
      break;
    case 11:
      colStartClass = 'lg:col-start-11 md:col-start-11';
      break;
    case 12:
      colStartClass = 'lg:col-start-12 md:col-start-12';
      break;
    default:
      colStartClass = '';
      break;
  }

  if (colStart !== null) {
    colSpanClass += ` col-start-${colStart}`;
  }

  return <div className={`${colSpanClass} ${colStartClass} ${className}`}>{children}</div>;
};

GridItem.defaultProps = {
  colSpan: 1,
  colStart: 0,
  children: '',
  className: '',
};
