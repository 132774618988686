import {
  GetGenericAccordionDocument,
  GetGenericAccordionQuery,
  GetGenericAccordionQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getGenericAccordion(id: string, locale: string) {
  return client.query<GetGenericAccordionQuery, GetGenericAccordionQueryVariables>({
    query: GetGenericAccordionDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
