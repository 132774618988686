import { client } from 'lib/graphql/client';
import {
  GetCountrySelectorDocument,
  GetCountrySelectorQuery,
  GetCountrySelectorQueryVariables,
} from 'generated/graphql';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getCountrySelectorCMSData(locale: string) {
  return client.query<GetCountrySelectorQuery, GetCountrySelectorQueryVariables>({
    query: GetCountrySelectorDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
    },
  });
}
