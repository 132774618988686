import { ElementType, FC } from 'react';
import { AccordionItemHeading, AccordionItemButton } from 'react-accessible-accordion';
import { IAccordionItem } from './types';
import { useTracking } from './hooks/useTracking';
import { Title } from 'components/Typography';
import clsx from 'clsx';

interface AccordionHeadingProps {
  id: string;
  className?: string;
  titleTogglePos: 'center' | 'top';
  index: number;
  $isNumbered?: boolean;
  item: IAccordionItem;
  NumberedSvg?: ElementType;
  page?: string;
  trackingAttributes?: string;
}

export const AccordionHeading: FC<AccordionHeadingProps> = ({
  id,
  className,
  titleTogglePos,
  index,
  item,
  $isNumbered,
  NumberedSvg,
  page,
  trackingAttributes,
}) => {
  const Tracking = useTracking({ page, trackingAttributes });
  const accordionItemButtonClassName = [
    'flex',
    'items-center',
    'cursor-pointer',
    'w-full',
    'text-left',
    'border-none',
    'relative',
    'outline-0',
    'py-[20px]',
    'px-0',
    'md:py-[34px]',
    'md:pr-[25px]',
    'aria-expanded:md:pb-[32px]',
    'before:content-["+"]',
    'aria-expanded:before:content-["–"]',
    'aria-selected:before:content-["–"]',
    'before:text-[30px]',
    'before:block',
    'before:absolute',
    'before:right-[10px]',
  ];
  return (
    <AccordionItemHeading data-test={[`${id}-item-heading`]} onClick={Tracking} data-index={index}>
      <AccordionItemButton
        className={clsx(
          `${className ?? 'accordion__button '} group`,
          {
            'before:top-[20px] md:before:top-[36px]': titleTogglePos === 'top',
            'before:top-[50%] before:-translate-y-[50%]': titleTogglePos === 'center',
          },
          accordionItemButtonClassName,
        )}
        data-test={[`${id}-item-button`]}
        data-print="hide-before-content"
      >
        {$isNumbered && (
          <div className="relative mr-3 w-11 h-11 md:mr-10" data-test={[`${id}-number-container`]}>
            {!!NumberedSvg && <NumberedSvg />}
            <Title
              className="absolute -inset-0 mt-px flex items-center justify-center"
              tag="div"
              type="xxxs"
              data-test={[`${id}-number`]}
            >
              {index + 1}
            </Title>
          </div>
        )}
        <div
          data-test={[`${id}-title`]}
          className="w-4/5 md:pr-0 hover:underline focus:underline"
        >
          {item.title}
          {item?.peek && <div className="peek-text group-aria-expanded:hidden">{item.peek}</div>}
        </div>
      </AccordionItemButton>
    </AccordionItemHeading>
  );
};
