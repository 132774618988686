import NextLink from "next/link";
import { AnchorHTMLAttributes } from "react";
import { Link } from "../../generated/graphql";
import { findInternalLink } from "../../lib/graphql/getGenericPageComponents";

/**
 * Custom Link implementation to avoid issues with NextJS domain routing & external links.
 * When using NextJS Link with external links and domain routing, the domain gets prepended,
 * i.e. https://company.cameo.ithttps//media.graphassets.com/q3DRmLufQUu6AzeS6 instead of https//media.graphassets.com/q3DRmLufQUu6AzeS6
 */
interface CorpLink extends AnchorHTMLAttributes<HTMLAnchorElement> { link: Link | undefined, children: any };
export const CorpLink = ({ link, children, ...props }: CorpLink) => {
  findInternalLink(link, '');

  // only use nextjs link if we have an internal link
  if (link?.internalLink) {
    return (
      <NextLink href={link?.url || ''} passHref {...props}>
        {children}
      </NextLink>
    );
  }

  // otherwise use plain anchor tag
  return <a href={link?.url || ''} target={link?.openInNewTab ? '_blank' : '_self'} rel="noreferrer" {...props} role="link">{children}</a>;
};