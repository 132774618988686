import { SyntheticEvent } from 'react';

export const useTracking = ({ page, trackingAttributes }: any) => {
  return (e: SyntheticEvent) => {
    if (!e || e?.currentTarget) return null;

    if (page === 'recipe') {
      const target = e.currentTarget.getAttribute('data-index');
      if (!target) return null;
      const stepNumber = parseInt(target, 10);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'view_preparation_steps_recipe',
        eventValues: {
          ec: 'teaser',
          ea: 'view',
          el: `${trackingAttributes}_${stepNumber + 1}`,
          preparation_step: `${stepNumber + 1}`,
        },
      });
    } else if (page === 'product') {
      const target = e.currentTarget;
      if (!target) return null;
      const accordionButton = target.querySelector('.accordion__button');

      if (!accordionButton) return null;
      if (!accordionButton.firstChild) return null;
      if (!accordionButton.firstChild.firstChild) return null;

      const firstChild = accordionButton.firstChild.firstChild as HTMLElement;

      const flag = accordionButton.getAttribute('aria-expanded');
      if (flag === 'false') {
        if (firstChild.innerHTML) {
          window.dataLayer = window.dataLayer || [];

          if (
            window.dataLayer.find(
              (item: any) =>
                item.eventValues &&
                item.eventValues.product_description_section === firstChild.innerHTML,
            ) === undefined
          ) {
            window.dataLayer.push({
              event: 'click_product_accordion',
              eventValues: {
                ec: 'product',
                ea: 'click product detail',
                el: `accordion_${firstChild.innerHTML}`,
                product_description_section: firstChild.innerHTML,
              },
            });
          }
        }
      }
    }
  };
};
