import { RichText } from '@graphcms/rich-text-react-renderer';
import { IAccordionItem } from './types';
import { FC } from 'react';

interface AccordionBodyProps {
  item: IAccordionItem;
  id: string;
}
export const AccordionBody: FC<AccordionBodyProps> = ({ item, id }) => {
  return item?.body?.raw ? (
    <div className=" mb-2.5 md:mb-5">
      <RichText content={item?.body?.raw} />
    </div>
  ) : (
    item?.body
  );
};
