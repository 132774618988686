import { Locale, Markets } from '../generated/graphql';
import { getLocale } from '../modules/localesMapper';
import { GetServerSideProps } from 'next';
import { IGlobalMetaData } from 'types/GlobalMetadata';
import React, { useEffect, useState } from 'react';
import { Title, Text } from '../components/Typography';
import { Grid, GridItem, GridRow } from '../components/Grid';

import { useRouter } from 'next/router';
import { getCopy } from 'lib/utils/getCopy';
import { getPressPageIntro } from 'lib/graphql/getPressPageIntro';
import { DictionaryItem } from '../types/Dictionary';
import { SearchCategorySelector } from '../components/SearchCategorySelector';
import { CorporateButton } from '../components/CorporateButton';
import styled from 'styled-components';
import { ShortHero } from '../components/ShortHero';
import Custom404 from './404';
import { getPageSearch, SiteSearchResultTypes } from '../lib/graphql/getPageSearch';
import { RightArrowIcon } from '../components/Icons/icons';

const FilterCategoryWrapper = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

const pageLimitPerType = 5;

export const getLocalizedURL = (
  type: keyof typeof SiteSearchResultTypes,
  locale: string,
  slug: string,
): string => {
  let subType = '';
  if (type === SiteSearchResultTypes.STORY) {
    subType = 'stories/';
  } else if (type === SiteSearchResultTypes.PRESSRELEASE) {
    subType = 'press/';
  }
  return `/${locale === 'default' ? '' : `${locale}/`}${subType}${slug}`;
};

export const getTypeText = (type: keyof typeof SiteSearchResultTypes, copy: any) => {
  if (type === SiteSearchResultTypes.PRESSRELEASE) {
    return getCopy('search_type_press', copy);
  }
  if (type === SiteSearchResultTypes.STORY) {
    return getCopy('search_type_story', copy);
  }
  return getCopy('search_type_page', copy);
};

function SearchPage({
  currMarket,
  currLocale,
  initalSearchData,
  pageData,
  serverError,
  dictionary,
}: {
  initalSearchData: any;
  pageData: any;
  searchValue: string;
  globalMetadata: IGlobalMetaData;
  currLocale: Locale;
  currMarket: Markets;
  serverError: boolean;
  dictionary: DictionaryItem[];
}) {
  const router = useRouter();
  const { s: searchValue } = router.query;
  const [searchData, setSearchData] = useState<any[]>(initalSearchData.data);
  const [total, setTotal] = useState<number>(initalSearchData.totalCount);
  const [hasMoreResults, setHasMoreResults] = useState<boolean>(initalSearchData.totalCount > 0);

  const [hasLoaded, setLoaded] = useState<boolean>(false);
  const [resultNumberShown, setResultNumberShown] = useState<number>(pageLimitPerType);

  let typeFilterValues: Record<string, string> = {
    '*': getCopy('all', dictionary),
    [SiteSearchResultTypes.PRESSRELEASE]: getCopy('search_type_press', dictionary),
    [SiteSearchResultTypes.STORY]: getCopy('search_type_story', dictionary),
    [SiteSearchResultTypes.PAGE]: getCopy('search_type_page', dictionary),
  };

  const [typeFilter, setTypeFilter] = useState<any | null>('*');

  const onUpdateSearchData = async (skip: number, reset: boolean) => {
    const newSearchData = await getPageSearch({
      first: pageLimitPerType,
      skip,
      search: (searchValue as string) || '',
      market: currMarket,
      locale: currLocale,
    });
    if (reset) {
      setSearchData([...newSearchData.data]);
    } else {
      setSearchData([...searchData, ...newSearchData.data]);
    }
    setTotal(newSearchData.totalCount);
    setHasMoreResults(newSearchData.data.length > 0);
  };

  useEffect(() => {
    if (!hasLoaded) setLoaded(true);
  });

  useEffect(() => {
    if (hasLoaded) {
      setResultNumberShown(pageLimitPerType);
      onUpdateSearchData(0, true);
    }
  }, [searchValue]);

  if (pageData && Array.isArray(pageData)) {
    pageData = pageData[0];
  }

  const loadMoreHandler = async (event: any) => {
    event.preventDefault();
    onUpdateSearchData(resultNumberShown, false);
    setResultNumberShown((val) => val + pageLimitPerType);
  };

  const filtered = [];
  for (let i = 0; i < searchData.length; i += 1) {
    if (typeFilter === '*' || searchData[i].type === typeFilter) {
      filtered.push(searchData[i]);
    }
  }

  if (serverError) {
    return <Custom404 dictionary={dictionary} />;
  }
  return (
    <Grid>
      <GridRow columns={16}>
        <GridItem colStart={3} colSpan={12}>
          <div className="text-center mt-12 md:mt-15 mb-12 md:mb-20">
            <Text type="lead" tag="h1" className="break-words font-normal text-darkgray">
              {getCopy('search_page_title', dictionary)}
            </Text>
            <Title type="lg" tag="h2" className="break-words mt-4">
              {getCopy('search_page_result_text', dictionary)
                .replace('{{results}}', total.toString())
                .replace('{{search}}', searchValue as string)}
            </Title>
          </div>
        </GridItem>
        <GridItem colStart={1} colSpan={14}>
          <FilterCategoryWrapper className="px-small pb-4 md:pb-10 2xl:px-medium 3xl:px-xlarge w-full">
            <div className="mb-4 md:mb-0 pb-2">
              <SearchCategorySelector
                selected={typeFilter === null ? '*' : typeFilter}
                categories={typeFilterValues}
                onSelect={(val: any) => setTypeFilter(val)}
              />
            </div>
          </FilterCategoryWrapper>
        </GridItem>
        <GridItem colStart={4} colSpan={10}>
          {filtered.map((item: any) => {
            const date = new Date(item.date);
            return (
              <a
                href={getLocalizedURL(item.type, currLocale, item.slug)}
                key={`${item.type}-${item.id}`}
                className="mb-10 border-b border-solid border-corporate-blue block"
              >
                <Text tag="p" type="lead" className="mb-7">
                  {getTypeText(item.type, dictionary)} /{' '}
                  {`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`}
                </Text>
                <Title tag="p" type="xs" className="mb-4">
                  {item?.title}
                </Title>
                <Text tag="p" type="mb" className="mb-5">
                  {item?.teaser}
                </Text>
                <Text tag="span" type="link" className="mb-9 flex items-center block">
                  {getCopy('read_more', dictionary)}
                  <RightArrowIcon className="ml-4 h-6" />
                </Text>
              </a>
            );
          })}
          {hasMoreResults && (
            <div className="flex justify-center mt-12 mb-20">
              <CorporateButton
                buttonType="button"
                type="button"
                color="primaryalternative"
                label={getCopy('load_more_button', dictionary)}
                onClick={loadMoreHandler}
              />
            </div>
          )}
        </GridItem>
      </GridRow>
    </Grid>
  );
}

export const getServerSideProps: GetServerSideProps = async ({
  locale = 'global',
  locales,
  // res,
  query,
}) => {
  const error = false;
  const { s } = query;
  const searchTerm: string = (s as string) || '';
  const market = locale; // locale from path is representing market
  const currLocale = getLocale(market);

  const pageData = await getPressPageIntro(currLocale);

  const initalSearchData = await getPageSearch({
    search: searchTerm,
    market: market as Markets,
    locale: currLocale as Locale,
    first: pageLimitPerType,
    skip: 0,
  });

  return {
    props: {
      currLocale,
      locales,
      currMarket: market,
      pageData: pageData.data.page,
      initalSearchData: initalSearchData,
      metaData: pageData.data.page[0].metaData,
      serverError: error,
      searchValue: s || '',
      algoliaData: null,
    },
  };
};
export default SearchPage;
