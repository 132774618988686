import {
  GetGenericAccordionTopRatedDocument,
  GetGenericAccordionTopRatedQuery,
  GetGenericAccordionTopRatedQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getGenericAccordionTopRated(id: string, locale: string) {
  return client.query<GetGenericAccordionTopRatedQuery, GetGenericAccordionTopRatedQueryVariables>({
    query: GetGenericAccordionTopRatedDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
