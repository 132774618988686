// TODO 2199 delete this file when QuickLink no longer in use in cms
import {
  GetQuickLinkDocument,
  GetQuickLinkQuery,
  GetQuickLinkQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getQuickLink(id: string, locale: string) {
  return client.query<GetQuickLinkQuery, GetQuickLinkQueryVariables>({
    query: GetQuickLinkDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
