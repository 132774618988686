import {
  GetGenericGridAuthorableDocument,
  GetGenericGridAuthorableQuery,
  GetGenericGridAuthorableQueryVariables,
  Locale,
  Scalars,
} from 'generated/graphql';
import { client } from './client';

export async function getGenericGridAuthorable(locale: string, id: string) {
  return client.query<GetGenericGridAuthorableQuery, GetGenericGridAuthorableQueryVariables>({
    query: GetGenericGridAuthorableDocument,
    variables: {
      locales: [locale as Locale],
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
