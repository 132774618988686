import React from 'react';
import {
  AccordionItem,
  AccordionItemPanel,
  Accordion as ReactAccordion,
} from 'react-accessible-accordion';
import { WavyCircleIcon } from '../Icons/icons';
import { IAccordionItem, IAccordionProps } from './types';
import { AccordionHeading } from './AccordionHeading';
import { useExpanded } from './hooks/useExpanded';
import { AccordionBody } from './AccordionBody';
import { AccordionHeadingWrapper } from './AccordionHeadingWrapper';
import clsx from 'clsx';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const Accordion = ({
  id = 'accordion',
  name,
  data,
  className,
  borderColor,
  $isNumbered = false,
  NumberedSvg = WavyCircleIcon,
  collapsed = false,
  HeadingWrapper = undefined,
  $forceTitleSpace,
  page,
  trackingAttributes,
  titleTogglePos = 'center',
  ...props
}: IAccordionProps): JSX.Element => {
  const { preExpanded } = useExpanded(collapsed, data);

  return (
    <ReactAccordion
      className={clsx('border-b-1 print:break-inside-avoid', {
        borderColor,
        '#bbc4ba': !borderColor,
      })}
      allowMultipleExpanded
      allowZeroExpanded
      preExpanded={preExpanded}
      style={{ borderColor: borderColor }}
      {...props}
    >
      {data?.map((item: IAccordionItem, index: number) => {
        return (
          <AccordionItem
            uuid={`${name}-${index}`}
            data-test={[`${id}-item`]}
            key={`${name}-${index}`}
            className="border-t-1 first:border-none"
            style={{ borderColor: borderColor }}
          >
            <AccordionHeadingWrapper Wrapper={HeadingWrapper}>
              <AccordionHeading
                page={page}
                trackingAttributes={trackingAttributes}
                id={id}
                titleTogglePos={titleTogglePos}
                index={index}
                item={item}
                $isNumbered={$isNumbered}
                NumberedSvg={NumberedSvg}
              />
            </AccordionHeadingWrapper>
            <AccordionItemPanel
              className={clsx('animate-fadeIn', {
                'sm:-mt-12 md:-mt-16': $forceTitleSpace === 'none' && !!item?.title,
                'md:-mt-14': $forceTitleSpace === 'mobile' && !!item?.title,
              })}
              data-test={[`${id}-panel`]}
              data-print="text-visible"
            >
              <AccordionBody item={item} id={id} />
            </AccordionItemPanel>
          </AccordionItem>
        );
      })}
    </ReactAccordion>
  );
};
