import {
  GetCorpSocialMediaFooterDocument,
  GetCorpSocialMediaFooterQuery,
  GetCorpSocialMediaFooterQueryVariables,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getCorpSocialMediaFooter(locale: string) {
  return client.query<GetCorpSocialMediaFooterQuery, GetCorpSocialMediaFooterQueryVariables>({
    query: GetCorpSocialMediaFooterDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
    },
  });
}
