import React, { Children, RefObject } from 'react';
import slugify from 'slugify';
import tw from 'tailwind-styled-components';
import { clsx } from 'clsx';

import { slugifyConfig } from '../../config/slugify';

export interface ITypography {
  tag: string;
  type: string;
  children?: React.ReactNode;
  id?: string;
  className?: string;
  itemProp?: string;
  ref?: RefObject<HTMLDivElement>;
  onMouseOver?: () => void;
  onKeyDown?: () => void;
  onMouseLeave?: () => void;
}

const TitleStyles: any = {
  xl: `
    text-6xl
    leading-11
    md:text-8xl
    md:leading-13
  `,
  lg: `
    text-4xl
    leading-9.5
    md:text-7xl
    md:leading-12
  `,
  md: `
    text-2xl
    leading-8
    md:text-6xl
    md:leading-11
  `,
  sm: `
    text-xl
    leading-7
    md:text-4xl
    md:leading-9.5
  `,
  xs: `
    text-lg
    leading-6
    md:text-2xl
    md:leading-8
  `,
  xxs: `
    text-base
    leading-6
    md:text-xl
    md:leading-7

  `,
  xxxs: `
    text-lg
    leading-6
  `,
};

const getTitleStyle = (type: any) => {
  const styles = `
    font-recoleta
    font-normal
    m-0
    p-0
    block
    ${TitleStyles[type]}
  `;
  return styles;
};

const titleTag = (props: any) => {
  const StyledTypography = tw(`${props.tag}` as any)`
    ${(p: any) => getTitleStyle(p.type)}
  `;

  const propsExtendable = { ...props };
  const numberOfChildren = Children.count(props?.children);

  if (numberOfChildren) {
    const combinedTextContent: string[] = [];

    Children.map(props.children, (child) => {
      combinedTextContent.push(child);
    });

    let id = slugify(combinedTextContent.join(' ') ?? '', slugifyConfig);
    Object.assign(propsExtendable, { id });
  }

  const element = React.createElement(StyledTypography, propsExtendable);
  return element;
};

export const Title = (props: ITypography) => <>{titleTag(props)}</>;

const TextStyles: any = {
  xxl: `
    font-geomanist
    text-2xxl
    leading-10.5
  `,
  xl: `
    font-geomanist
    text-2xl
    leading-9
  `,
  lg: `
    font-geomanist
    text-base
    leading-relaxed
    md:text-xl
    md:leading-7.5
  `,
  md: `
    font-geomanist
    text-sm
    leading-6
    md:text-base
    md:leading-relaxed
    tracking-wide
  `,
  sm: `
    font-geomanist
    text-xs
    leading-5.5
    md:text-sm
    md:leading-6
  `,
  xs: `
    font-geomanist
    text-sm
    leading-6
    md:font-light
  `,
  lead: `
    font-geomanist
    text-xs
    leading-4
    uppercase
    tracking-xl
    lg:text-sm
    lg:leading-4.5
  `,
  leadxs: `
    font-geomanist
    text-xss
    leading-4
    uppercase
    tracking-xl
    md:text-xs
  `,
  link: `
    font-geomanist
    text-base
    font-medium
    uppercase
    tracking-widest
    leading-none
  `,
  list: `
    font-geomanist
    text-sm
    leading-5.5
    md:text-xl
    md:leading-7.5
  `,
  listsm: `
    font-recoleta
    font-medium
    text-lg
    leading-6
  `,
};

const getTextStyle = (type: any) => {
  const styles = `
    m-0
    p-0
    block
    ${TextStyles[type]}
  `;
  return styles;
};

const textTag = (props: any) => {
  const StyledTypography = tw(`${props.tag}` as any)`
    ${(p: any) => getTextStyle(p.type)}
  `;
  const element = React.createElement(StyledTypography, props);
  return element;
};

// Text
export const Text = (props: ITypography) => <>{textTag(props)}</>;


// Headline
export interface HeadlineProps {
  children: React.ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  type?: 'xl' | 'l' | 'm' | 's' | 'xs' | 'xxs' | 'xxxs';
  className?: string;
}

export const Headline: React.FC<HeadlineProps> = ({
  children,
  as: Component = 'h1',
  type = 'xl',
  className,
}) => (
  <Component
    className={clsx(
      [`text-headline-${type} leading-headline-${type} tracking-headline-${type}`, 'font-medium'],
      className,
    )}
  >
    {children}
  </Component>
);


// Other Text
export interface TextProps {
  children: React.ReactNode;
  as?: 'p' | 'span';
  variant?: 'body' | 'lead';
  type?: 'xxl' | 'xl' | 'l' | 'm' | 's';
  className?: string;
}

export const OtherText: React.FC<TextProps> = ({
  children,
  variant = 'body',
  as: Component = 'p',
  type = 'm',
  className,
}) => {
  return (
    <Component
      className={clsx(
        [`text-${variant}-${type} leading-${variant}-${type} tracking-${variant}-${type}`],
        className,
      )}
    >
      {children}
    </Component>
  );
};
