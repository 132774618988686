import { IAccordionItem } from '../types';

export const useExpanded = (collapsed: boolean, data: IAccordionItem[] | undefined) => {
  const preExpanded = [];

  if (!collapsed && data) {
    for (let i = 0; i < data.length; i += 1) {
      preExpanded.push(`${i}`);
    }
  }

  return {
    preExpanded,
  };
};
