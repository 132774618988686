import React from 'react';
import { Text } from '../Typography';
import { CategorySliderWrapper } from './styles';

export const SearchCategorySelector = ({
  id = 'search-category-selector',
  categories,
  selected,
  onSelect,
}: {
  id?: string;
  categories: Record<string, string>;
  selected: string;
  onSelect: (arg0: string) => void;
}) => (
  <CategorySliderWrapper>
    <div className="overflow-x-auto w-full">
      <div id={id} data-test={id} className="text-center md:text-left whitespace-nowrap">
        {Object.keys(categories).map((cat) => (
          <button
            key={cat}
            type="button"
            onClick={() => onSelect(cat)}
            className={`inline-block pt-2 mr-5 md:mr-30 ${selected === cat ? 'border-b-2' : ''}`}
          >
            <Text tag="span" type="md" className="whitespace-nowrap">
              {categories[cat]}
            </Text>
          </button>
        ))}
      </div>
    </div>
  </CategorySliderWrapper>
);
