import React, { useEffect, useState } from 'react';
import { Text, Title } from '../Typography';
import {
  Container,
  CountryBox,
  CountryList,
  CountryListStyleCorporate,
  CSButton,
  Flag,
  FormElements,
  FormLabel,
  Label,
  LanguageBox,
  LanguageList,
  LanguageListStyleCorporate,
  MainFlagIcon,
  MainInput,
  MainLabel,
} from './styles';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import { CountrySelectorList, CountrySelectorType, LanguageListType } from './types';
import { SelectBoxArrowDownWhite, SelectBoxArrowUpWhite } from '../Icons/icons';

export const CountrySelector = ({ data, initialCountryCode, onChange }: CountrySelectorType) => {
  const firstDataItem =
    data?.countrySelectorList &&
    data?.countrySelectorList.find((item) => item.countryCode === initialCountryCode);
  const firstData = firstDataItem || data?.countrySelectorList?.[0];
  const defaultCountry = firstData?.country;
  const defaultLanguage = firstData?.language?.[0]?.displayName;
  const initialLangLength = firstData?.language?.length;
  const initialList = firstData?.language;
  const initialLink = firstData?.language?.[0].url;
  const initialFlagIcon = firstData?.countryFlag?.asset?.[0].url;
  const [selectedCountry, setSelectedCountry] = useState<string>(defaultCountry || '');
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>(initialCountryCode || '');
  const [langList, setLangList] = useState<any>(initialList);
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(
    defaultLanguage || '',
  );
  const [showCountry, setShowCountry] = useState<boolean>(false);
  const [showlanguages, setShowlanguages] = useState<boolean>(false);
  const [link, setLinks] = useState<string | undefined>(initialLink || '');
  const [countriesList, setCountriesList] = useState<any>({});
  const [languagesList, setlanguagesList] = useState<any>({});
  const [countryFlag, setCountryFlag] = useState<string>(initialFlagIcon || '');
  useEffect(() => {
    const countryListComp = document.querySelectorAll('.country-list');
    const languageListComp = document.querySelectorAll('.language-list');
    setCountriesList(countryListComp);
    setlanguagesList(languageListComp);
    // set intital selected state
    countryListComp.forEach((countryListItem) => {
      if (
        initialCountryCode &&
        countryListItem &&
        countryListItem.getAttribute('data-countrycode')?.toLocaleLowerCase() ===
          initialCountryCode?.toLocaleLowerCase()
      ) {
        countryListItem?.classList?.add('selected');
      }
    });
    languageListComp[0]?.classList?.add('selected');
  }, []);
  useEffect(() => {
    const countryListComp = document.querySelector('.show-languages');
    if (langList?.length !== 1) {
      countryListComp?.classList.remove('hidden');
    } else {
      countryListComp?.classList.add('hidden');
    }
  });
  const getCountry = (event: any) => {
    for (let i = 0; i <= countriesList.length; i += 1) {
      if (countriesList[i]?.classList?.contains('selected')) {
        countriesList[i]?.classList?.remove('selected');
      }
    }
    const targetVal = event.target.getElementsByTagName('input');
    const targetCode = event.target.getAttribute('data-countrycode');
    const country = data?.countrySelectorList?.filter((countrylist: CountrySelectorList) => {
      let countryItem;
      if (countrylist.countryCode === targetCode) {
        countryItem = countrylist;
      }
      return countryItem;
    });
    if (country && country.length) {
      setLangList(country[0]?.language);
      setSelectedLanguage(country[0]?.language?.[0]?.displayName);
      setLinks(country[0]?.language?.[0]?.url);
    }
    if (targetVal.length) {
      setSelectedCountry(targetVal[0]?.value);
      const flagIcon = targetVal[0]?.getAttribute('data-flag');
      setCountryFlag(flagIcon);
      setSelectedCountryCode(targetVal[0]?.getAttribute('data-countrycode'));
      setTimeout(() => {
        targetVal[0].closest('li')?.classList?.add('selected');
      }, 100);
    }
    setShowCountry(false);
  };
  const getLanguage = (event: any) => {
    for (let i = 0; i <= languagesList?.length; i += 1) {
      if (languagesList[i]?.classList?.contains('selected')) {
        languagesList[i]?.classList?.remove('selected');
      }
    }
    const target = event.target.getElementsByTagName('input');
    const dataLink = target[0]?.dataset?.countrylink;
    if (target.length) {
      setSelectedLanguage(target[0]?.value);
      setLinks(dataLink);
      setTimeout(() => {
        target[0]?.closest('li')?.classList?.add('selected');
      }, 100);
    }
    setShowlanguages(false);
  };
  const showCountries = () => {
    setShowCountry(!showCountry);
  };

  const showLanguages = () => {
    setShowlanguages(!showlanguages);
  };

  const hasCountryFlag = countryFlag && countryFlag.length > 0;
  return (
    <Container data-test="Country-selector">
      <Title tag="h3" type="xs" className={`text-white md:mb-30 sm:mb-10`}>
        {data?.title}
      </Title>
      <>
        <FormElements data-test="footer-form-elements-country">
          <MainLabel htmlFor="country">
            <FormLabel tag="span" type="lead" className={`text-white`}>
              {data?.countryLabel}
            </FormLabel>
            {hasCountryFlag && (
              <MainFlagIcon>
                <Flag
                  data-src={countryFlag}
                  src={countryFlag}
                  className="align-top lazyload"
                  width="18"
                  height="18"
                />
              </MainFlagIcon>
            )}
            <MainInput
              type="text"
              id="country"
              value={selectedCountry}
              onChange={() => {}} // ! added to remove errors. Migrate towards onChange rather than building behavior based on onClick
              className={`rounded-2xl pr-5 ${
                hasCountryFlag ? 'pl-12' : 'pl-5'
              } py-2.5 font-geomanist tracking-wider w-full !bg-transparent outline-0	 md:text-md md:leading-26  sm:text-sm sm:leading-6 text-white placeholder-white border-white`}
              onClick={showCountries}
            />
            <div className="absolute right-5 bottom-3">
              {showCountry ? <SelectBoxArrowUpWhite /> : <SelectBoxArrowDownWhite />}
            </div>
          </MainLabel>
          <CountryBox className={`${!showCountry ? 'hidden' : ''}`}>
            {data?.countrySelectorList?.map((countries: CountrySelectorList) => (
              <CountryList
                className={CountryListStyleCorporate}
                key={countries?.id}
                data-countrycode={countries.countryCode}
              >
                <Label
                  htmlFor={countries?.countryCode + countries?.id}
                  onClick={getCountry}
                  className="text-white"
                  data-countrycode={countries.countryCode}
                >
                  {countries?.countryFlag && (
                    <Flag
                      data-src={countries?.countryFlag?.asset?.[0]?.url}
                      className="align-middle lazyload"
                      width="18"
                      height="18"
                    />
                  )}
                  <input
                    type="radio"
                    name="Country"
                    id={countries?.countryCode + countries?.id}
                    defaultValue={countries?.country}
                    className="hidden"
                    data-countrycode={countries.countryCode}
                    data-flag={countries?.countryFlag?.asset?.[0]?.url || ''}
                  />
                  {countries?.country}
                </Label>
              </CountryList>
            ))}
          </CountryBox>
        </FormElements>
        <FormElements
          data-test="footer-form-elements-language"
          className={'bg-corporate-blue bg-opacity-75 sm:mt-10 md:mt-0'}
        >
          <MainLabel
            htmlFor="language"
            className={
              langList?.length === 1 || initialLangLength === 1 ? 'hidden show-languages' : ''
            }
          >
            <FormLabel tag="span" type="lead" className={'text-white'}>
              {data?.languageLabel}
            </FormLabel>
            <input
              type="text"
              id="language"
              value={selectedLanguage}
              onChange={() => {}} //TODO: we should use just a onChange simeple event rather than the complexity that we have with the on click atm.
              className={`rounded-2xl px-5 py-2.5 font-geomanist tracking-wider w-full !bg-transparent md:text-md md:leading-26 sm:text-sm sm:leading-6 text-white placeholder-white border-white`}
              onClick={showLanguages}
            />
            <div className="absolute right-5 bottom-3">
              {showlanguages ? <SelectBoxArrowUpWhite /> : <SelectBoxArrowDownWhite />}
            </div>
          </MainLabel>
          <LanguageBox className={`${!showlanguages ? 'hidden' : ''} bg-white`}>
            {langList?.map((languages: LanguageListType) => (
              <LanguageList className={LanguageListStyleCorporate} key={languages?.id}>
                <Label
                  htmlFor={`lang${languages?.id}`}
                  onClick={getLanguage}
                  data-redirect={languages?.url}
                >
                  <input
                    type="radio"
                    name="Language"
                    id={`lang${languages?.id}`}
                    defaultValue={languages?.displayName}
                    data-countrylink={languages?.url}
                    className="hidden"
                  />
                  {languages?.displayName}
                </Label>
              </LanguageList>
            ))}
          </LanguageBox>
        </FormElements>
        <FormElements
          data-test="footer-form-elements-button"
          className="sm:text-center md:text-left"
        >
          <CSButton
            className={'text-white border-white'}
            href={link}
            onClick={(e) => {
              if (onChange) {
                e.preventDefault();
                onChange(selectedCountryCode);
              }
            }}
          >
            <Text tag="span" type="lead">
              {data?.textOnButton}
            </Text>
          </CSButton>
        </FormElements>
      </>
    </Container>
  );
};
