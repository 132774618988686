import React, { useEffect, useState } from 'react';

import { CloseButton, CustomTitle, Description } from './styles';

import { BannerType, ICorpNotificationBannerProps } from './types';
import {
  Grid,
  GridRow,
  GridItem,
} from './../Grid';
import { CorporateButton } from './../CorporateButton';
import { CloseIcon } from '../Icons/icons';
import clsx from 'clsx';

export const CorpNotificationBanner = ({
  id = 'corp-notification-banner',
  title,
  description,
  link,
  active = true,
  bannerType,
}: ICorpNotificationBannerProps) => {
  const [hidden, setHidden] = useState(false);
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const screenSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    screenSize();
    window.addEventListener('load', screenSize);
    window.addEventListener('resize', screenSize);
    return () => window.removeEventListener('resize', screenSize);
  }, []);
  if (hidden || !active) {
    return <></>;
  }
  return (
    <div
      className={clsx('py-4', {
        'bg-corporate-lightBlue': bannerType !== BannerType.RECALL_CAMPAIGN,
        'bg-corporate-red text-white': bannerType === BannerType.RECALL_CAMPAIGN,
      })}
    >
      <div className="text-center lg:text-left">
        <Grid>
          <div className="lg:flex items-center h-full justify-between">
            <div className="lg:flex items-center lg:mr-4">
              <div className="text-right">
                <CloseButton className="lg:mr-4" onClick={() => setHidden(true)}>
                  <span>
                    <CloseIcon />
                  </span>
                </CloseButton>
              </div>
              <CustomTitle tag="p" type="xxs">
                {title}
              </CustomTitle>
            </div>
            <Description className="lg:mr-4" tag="p" type="md">
              {description}
            </Description>
            {link?.url && (
              <a
                className="inline-block mt-4 lg:mt-0"
                href={link.url}
                target={link.openInNewTab ? '_blank' : '_self'}
                rel="noreferrer"
              >
                <span className="sr-only">{link.displayName}</span>
                <CorporateButton
                  label={link.displayName}
                  type="button"
                  buttonType="button"
                  color="tertiary"
                  className={
                    bannerType === BannerType.RECALL_CAMPAIGN
                      ? 'hover:text-black hover:border-black border-white text-white'
                      : ''
                  }
                />
              </a>
            )}
          </div>
        </Grid>
      </div>
    </div>
  );
};
