import {
  GetCorpNotificationBannerDocument,
  GetCorpNotificationBannerQuery,
  GetCorpNotificationBannerQueryVariables,
  Markets,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getCorpNotificationBanner(locale: string, market: Markets) {
  return client.query<GetCorpNotificationBannerQuery, GetCorpNotificationBannerQueryVariables>({
    query: GetCorpNotificationBannerDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        market,
      },
    },
  });
}
