/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.div<{
  isVisible: boolean;
  delay: string;
  fadeIn: boolean;
  bounce: boolean;
  spinUp: boolean;
  $componentSize: 'Short' | 'Tall';
}>`
  ${({ spinUp }) =>
    spinUp
      ? `opacity: 0;
      @keyframes spin-up {
        from {
          transform: rotate(0deg);
          top: 500px;
          position: absolute;
        }
        to {
          transform: rotate(360deg);
          top: 0px;
          position: relative;
        }
      }
      svg {
        animation-name: spin-up;
        animation-duration: 4s;
      }
    `
      : `opacity: 0;`};

  ${({ bounce, $componentSize }) =>
    bounce
      ? `
        opacity: 0;
        @keyframes bounce {
          from { transform: translateY(110%); opacity: 0; }
          50% { transform: translateY(-10%); opacity: 1; }
          to { transform: translateY(0%); opacity: 1; }
        }
      `
      : `
        opacity: 0;
        ${
          ($componentSize === 'Short' && `transform: translateY(10vh);`) ||
          `transform: translateY(20vh);`
        }
      `}

  @media(prefers-reduced-motion: no-preference) {
    ${({ fadeIn, delay }) =>
      fadeIn &&
      `
      transition: opacity ${delay} ease-in;
      will-change: opacity;
    `};

    ${({ bounce, isVisible }) =>
      bounce &&
      isVisible &&
      `
      animation-name: bounce;
      animation-duration: 1500ms;
    `};

    ${({ fadeIn, bounce }) =>
      !fadeIn &&
      !bounce &&
      `
      transition: opacity 1200ms ease-out, transform 600ms ease-out;
      will-change: opacity, transform;
    `};

    ${({ delay }) => delay && `transition-delay: ${delay}; animation-delay: ${delay};`}
  }

  ${(p) =>
    p.isVisible
      ? `
      opacity: 1;
      transform: none;
    `
      : ''};
`;

const FadeInSection = (props: any) => {
  const [isVisible, setVisible] = useState(props.isVisible || false);
  const domRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  useEffect(() => {
    if (!isVisible) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => setVisible(entry.isIntersecting));
      });
      const currElement = domRef.current!;
      observer.observe(currElement);
      return () => observer.unobserve(currElement);
    }
    return;
  }, [isVisible]);
  return (
    <Container
      isVisible={isVisible}
      ref={domRef}
      delay={props.delay}
      fadeIn={props.fadeIn}
      bounce={props.bounce}
      spinUp={props.spinUp}
      $componentSize={props.$componentSize}
    >
      {props.children}
    </Container>
  );
};

export default FadeInSection;
