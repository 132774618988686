import {
  GetGenericFollowUsDocument,
  GetGenericFollowUsQuery,
  GetGenericFollowUsQueryVariables,
  Scalars,
} from 'generated/graphql';
import { getMarket } from 'modules/localesMapper';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getGenericFollowUs(locale: string, id?: string) {
  const market = locale ? getMarket(locale) : undefined;
  return client.query<GetGenericFollowUsQuery, GetGenericFollowUsQueryVariables>({
    query: GetGenericFollowUsDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        ...(id && { id: id as Scalars['ID'] }),
        // ...(market && { market: market as Markets }),
      },
    },
  });
}
