import { GetFooterDocument, GetFooterQuery, GetFooterQueryVariables } from 'generated/graphql';
import { client } from 'lib/graphql/client';
import { getLocalesForGraphQL } from '../utils/helpers';

export function getFooterNavigation(locale: string) {
  return client.query<GetFooterQuery, GetFooterQueryVariables>({
    query: GetFooterDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
    },
  });
}
