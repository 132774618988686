import {
  GetCorpCalendarEventComponentDocument,
  GetCorpCalendarEventComponentQuery,
  GetCorpCalendarEventComponentQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getCorpCalendarEventComponent(id: string, locale: string): Promise<any> {
  const variables: GetCorpCalendarEventComponentQueryVariables = {
    locales: getLocalesForGraphQL(locale),
    where: {
      id: id as Scalars['ID'],
    },
  };

  try {
    const data = await client.query<GetCorpCalendarEventComponentQuery, GetCorpCalendarEventComponentQueryVariables>({
      query: GetCorpCalendarEventComponentDocument,
      variables,
    });

    if (data) {
      return data;
    } else {
      throw new Error('Event not found');
    }
  } catch (e) {
    throw new Error(`getCorpCalendarEventComponent error: ${e instanceof Error ? e.message : String(e)}`);
  }
}
