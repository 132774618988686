import styled, { css, keyframes } from 'styled-components';
import tw from 'tailwind-styled-components';

export const NavigationContainer = tw.nav`
  w-full
  fixed
  z-50
  w-screen
  top-0
`;

export const NavBar = styled.div`
  border-bottom: 1px solid rgba(1, 14, 84, 1);
  > * {
    width: calc(100% / 3);
  }
`;

export const NavLogo = styled.a`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  align-items: center;
  > span {
    margin-top: 0.2rem;
    border-top: 1px solid;
    display: block;
    font-size: 0.6rem;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
  svg {
    height: 28px;
  }
`;

export const SearchButton = styled.button`
  svg {
    width: auto;
    height: 100%;
  }
`;

const maxListWidth = 480;

const $shared = css`
  position: absolute;
  overflow-y: auto;
  top: 3.5rem;
  left: 0;
  transform: translateX(-100vw);
  height: calc(100vh - 3.5rem);
  width: 100vw;
  transition: transform 0.18s ease-in;
  @media (min-width: 768px) {
    width: 50vw;
    max-width: ${maxListWidth}px;
  }
`;

export const NavFirstLevel = styled.ul<{
  $isOpen?: boolean;
}>`
  ${$shared}
  ${(props) => props.$isOpen && 'transform: translateX(0);'}
  padding: 0;
  background-color: #e6e7ee;
  z-index: 20;
  @media (min-width: 768px) {
    border-color: rgba(1, 14, 84, 1);
    border-right: 1px solid;
  }
`;

export const NavSecondLevel = styled.ul<{
  $isOpen?: boolean;
}>`
  ${$shared}
  border: none;
  ${(props) => props.$isOpen && 'transform: translateX(0);'}
  z-index: 30;
  @media (min-width: 768px) {
    z-index: 10;
    ${(props) => props.$isOpen && 'transform: translateX(50vw);'}
  }
  @media (min-width: 960px) {
    ${(props) => props.$isOpen && `transform: translateX(${maxListWidth}px);`}
  }
  background-color: #FDFBF9;
  border-color: #FDFBF9;
  .corp-nav-item + .corp-nav-item-highlighted {
    margin-top: 1.5rem;
  }
`;

export const NavItem = styled.li<{
  $highlighted?: boolean;
  $secondLevelItem?: boolean;
}>`
  ${(props) => props.$highlighted && `
  background-color: #e6e7ee; 
  border-bottom: 1px solid rgba(1, 14, 84, 1);
  `}
  ${(props) =>
    props.$secondLevelItem &&
    ` :not(:nth-child(2)):not(.corp-nav-item-highlighted) {
    margin-top: 1.5rem;
    }`}
  :last-child:not(.corp-nav-item-highlighted) {
    margin-bottom: 1.5rem;
  }
  > a:hover,
  > button:hover {
    text-decoration: underline;
  }
`;
export const NavItemBack = tw.li`
  flex
  md:hidden 
  pl-10 
  py-12
`;

export const MenuButtonText = tw.button`
  p-5
  flex
`;

const fadeAnimation = keyframes`
  from{opacity: 1;}
  to{opacity: 0;}
`;
const rotateLAnimation = keyframes`
from{transform: translateY(0px) rotate(0);}
to{transform: translateY(-15px) rotate(-45deg);}
`;
const rotateRAnimation = keyframes`
from{transform: translateY(0px) rotate(0);}
to{transform: translateY(15px) rotate(45deg);}
`;
export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  .menu {
    height: 100%;
    position: relative;
    margin-top: -5px;
    border: 5px solid transparent;
    cursor: pointer;
    transform: scale(0.5);
    .bar {
      height: 5px;
      width: 50px;
      display: block;
      margin: 10px auto;
      position: relative;
      background-color: rgb(41, 43, 41);
      transition: all 0.4s;
    }
    &.open .bar {
      &:nth-of-type(1) {
        animation: ${rotateRAnimation} 1.5s cubic-bezier(0.5, 0.2, 0.2, 1) ease forwards;
        transform: translateY(15px) rotate(45deg);
      }
      &:nth-of-type(2) {
        animation: ${fadeAnimation} 1.5s cubic-bezier(0.1, 0.8, 0.1, 1) ease forwards;
        opacity: 0;
      }
      &:nth-of-type(3) {
        animation: ${rotateLAnimation} 1.5s cubic-bezier(0.5, 0.2, 0.2, 1) ease forwards;
        transform: translateY(-15px) rotate(-45deg);
      }
    }
  }
`;
