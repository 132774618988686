import {
  GetImageCopyDocument,
  GetImageCopyQuery,
  GetImageCopyQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getImageCopy(id: string, locale: string) {
  return client.query<GetImageCopyQuery, GetImageCopyQueryVariables>({
    query: GetImageCopyDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
