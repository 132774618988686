import {
  GetFeaturedContentDocument,
  GetFeaturedContentQuery,
  GetFeaturedContentQueryVariables,
  Locale,
  Scalars,
} from 'generated/graphql';
import { client } from './client';

export async function getFeaturedContent(locale: string, id: string) {
  return client.query<GetFeaturedContentQuery, GetFeaturedContentQueryVariables>({
    query: GetFeaturedContentDocument,
    variables: {
      locales: [locale as Locale],
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
