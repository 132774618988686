import {
  GetSpacerDocument,
  GetSpacerQuery,
  GetSpacerQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getSpacer(id: string, locale: string) {
  return client.query<GetSpacerQuery, GetSpacerQueryVariables>({
    query: GetSpacerDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
