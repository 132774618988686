import {
  GetGenericLineDocument,
  GetGenericLineQuery,
  GetGenericLineQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getGenericLine(id: string, locale: string) {
  return client.query<GetGenericLineQuery, GetGenericLineQueryVariables>({
    query: GetGenericLineDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
