import {
  GetPageSearchDocument,
  GetPageSearchQuery,
  GetPageSearchQueryVariables,
  Locale,
  Locales,
  Markets,
} from 'generated/graphql';
import { client } from 'lib/graphql/client';

export const SiteSearchResultTypes = {
  STORY: 'STORY',
  PRESSRELEASE: 'PRESSRELEASE',
  PAGE: 'PAGE',
};
export async function getPageSearch({
  search,
  market,
  locale,
  first = 999,
  skip = 0,
}: {
  search: string;
  market: Markets;
  locale: Locale;
  first?: number;
  skip?: number;
}): Promise<{ totalCount: number; data: any[] }> {
  // show german content on at and ch
  const locales = [locale];
  const storyPressMarkets = [market];
  if (market === Markets.at || market === Markets.ch) {
    locales.push(Locale.de);
    storyPressMarkets.push(Markets.de);
  }
  locales.push(Locale.default);

  const pageSearch = await client.query<GetPageSearchQuery, GetPageSearchQueryVariables>({
    query: GetPageSearchDocument,
    variables: {
      search: search,
      locales,
      localeGlobalPressRelease: locale,
      storyPressMarkets,
      pagesMarket: market,
      first: first,
      skip: skip,
    },
  });

  // add types
  const pressReleases = pageSearch?.data.pressReleases.edges.map((item: any) => {
    return {
      ...item.node,
      type: SiteSearchResultTypes.PRESSRELEASE,
    };
  });

  // we want global press releases as well, but only if they have translations in the current locale
  const pressReleasesGlobal = pageSearch?.data.pressReleasesGlobal.edges.map((item: any) => {
    return {
      ...item.node,
      type: SiteSearchResultTypes.PRESSRELEASE,
    };
  });

  const stories = pageSearch?.data.stories.edges.map((item) => {
    return {
      ...item.node,
      type: SiteSearchResultTypes.STORY,
    };
  });

  const pages = pageSearch?.data.pages.edges.map((item) => {
    return {
      ...item.node,
      date: item.node.createdAt,
      title: item.node?.metaData?.pageName || null,
      teaser: item.node?.metaData?.description || null,
      type: SiteSearchResultTypes.PAGE,
    };
  });

  let arr = [...pressReleases, ...stories, ...pages];
  let totalCount =
    pageSearch?.data?.pages?.aggregate?.count +
    pageSearch?.data?.pressReleases?.aggregate?.count +
    pageSearch?.data?.stories?.aggregate?.count;
  if (market !== Markets.global) {
    arr = arr.concat(pressReleasesGlobal);
    totalCount += pageSearch?.data?.pressReleasesGlobal?.aggregate?.count;
  }

  // sort by date
  const sorted = arr.sort((a, b) => (Date.parse(a.date) < Date.parse(b.date) ? 1 : -1));

  return {
    totalCount,
    data: sorted,
  };
}
