import React from 'react';
import { Text } from './../Typography';
import { AnchorNavigationLinkList, AnchorNavigationWrapper } from './styles';
import { ILink } from './types';

export const CorpAnchorNavigation = ({
  links,
  id,
  backgroundColor = '#fff',
}: {
  links?: ILink[];
  id?: string;
  backgroundColor?: string;
}) => {
  const linkList = links?.map((link) => (
    <a href={`#${link.url}`} key={link.id} data-test={`anchor-link-${id}`}>
      <Text tag="span" type="list">
        {link.displayName}
      </Text>
    </a>
  ));

  return (
    <AnchorNavigationWrapper
      $backgroundColor={backgroundColor}
      data-test={`anchor-navigation-${id}`}
      className="sticky sm:top-14 md:top-20 bg-white z-22"
    >
      <AnchorNavigationLinkList>
        <div>{linkList}</div>
      </AnchorNavigationLinkList>
    </AnchorNavigationWrapper>
  );
};

CorpAnchorNavigation.defaultProps = {
  id: '',
};

export default CorpAnchorNavigation;
