import getConfig from 'next/config';
import { Locale, Markets, Stage } from '../../generated/graphql';

export const JSONCleaner = (json: Object): Object => {
  const deepCopy = JSON.parse(JSON.stringify(json));
  Object.keys(deepCopy).forEach((key: string) => {
    if (!deepCopy[key] && deepCopy[key] !== undefined) {
      delete deepCopy[key];
    }
  });
  return deepCopy;
};

export const lowerCaseFirstLetter = (string: string): string =>
  `${string.charAt(0).toLowerCase()}${string.slice(1)}`;

export const capitalizeOnlyFirstLetter = (string: string): string =>
  `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;

export const getPictureSources = (image: any) => {
  const sources = [];
  if (image?.mobile) {
    sources.push({
      srcSet: image.mobile,
      type: 'image/webp',
      media: '(max-width: 767px)',
    });
  }
  if (image?.desktop) {
    sources.push({
      srcSet: image.desktop,
      type: 'image/webp',
    });
  }
  if (image?.mobileFallback) {
    sources.push({
      srcSet: image.mobileFallback,
      media: '(max-width: 767px)',
    });
  }
  if (image?.desktopFallback) {
    sources.push({
      srcSet: image.desktopFallback,
    });
  }
  return sources;
};

// we do not want to index pages other than global, it or de markets or if alwaysIndex is set
export const doNotIndexPage = (
  page: {
    alwaysIndex?: boolean | null | undefined;
  },
  currMarket: Markets | string,
) =>
  !page.alwaysIndex &&
  currMarket !== Markets.de &&
  currMarket !== Markets.global &&
  currMarket !== Markets.it;

// we do not want to index pages on any market
export const doNotIndexPageOnAnyMarket = (page: {
  alwaysIndex?: boolean | null | undefined;
}): boolean => {
  return page.alwaysIndex ?? true;
};

export const getCMSStage = (): Stage => {
  const { publicRuntimeConfig } = getConfig();
  const stageFromEnv = publicRuntimeConfig?.stage;
  if (stageFromEnv && stageFromEnv in Stage) {
    return stageFromEnv as Stage;
  } else {
    console.error(
      `Stage value "${stageFromEnv}" from env file is not a know stage type. Default to "PUBLISHED".`,
    );
  }
  return Stage.PUBLISHED;
};

// for some locales, we have to add more fallback languages
export const getLocalesForGraphQL = (locale: Locale | string): Locale[] => {
  const ret = [locale];

  switch (locale) {
    case Locale.at:
    case Locale.ch:
      ret.push(Locale.de);
      break;
  }

  // always add default localization as last fallback
  ret.push(Locale.default);

  return ret as Locale[];
};
