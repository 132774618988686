import React, { useState, useEffect, useRef } from 'react';
import {
  SearchOverlayWrapper,
  GridContent,
  SubHeading,
  Heading,
  SearchInput,
  SearchOverlayWrapperStyle,
  HitWrapper,
  CloseButton,
} from './styles';
import { Grid, GridRow, GridItem } from './../Grid';
import { getPageSearch, SiteSearchResultTypes } from '../../lib/graphql/getPageSearch';
import { Locale, Markets } from '../../generated/graphql';
import { Title, Text } from './../Typography';
import { useRouter } from 'next/router';
import { CorporateButton } from './../CorporateButton';
import { useDebounce } from '../../lib/hooks/useDebounce';
import { getLocalizedURL, getTypeText } from '../../pages/search';
import { getCopy } from '../../lib/utils/getCopy';
import { CloseIcon } from '../Icons/icons';

// Uses Algolia Instant Search React widgets
// See: https://www.algolia.com/doc/api-reference/widgets/react/

const HitComponent = (props: { hit: any; locale: string, copy: any }) => {
  const { hit, locale, copy } = props;
  const date = new Date(hit.date);
  return <HitWrapper href={getLocalizedURL(hit.type, locale, hit.slug)} className="mb-8 block">
    <Text tag="p" type="leadxs" className="text-darkgray mb-2">{getTypeText(hit.type, copy)} / {`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`}</Text>
    <Title tag="p" type="xs">{hit?.title}</Title>
  </HitWrapper>
}

export const CorpSearchOverlay = ({
  setOpenSearch,
  locale,
  market,
  copy,
}: {
  setOpenSearch: (open: boolean) => void;
  market: string;
  locale: string;
  copy: any;
}) => {
  const router = useRouter();
  const maxResults = 4;
  const inputRef = useRef<any>();
  const [hits, setHits] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    setOpenSearch(false);
    router.push({
      pathname: `/${market === 'global' ? '' : `${market}/`}search`,
      query: { s: searchValue },
    });
  }

  // const handelDataLayerPush = () => {
  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({
  //     event: 'click_search_enter',
  //     eventValues: {
  //       ec: 'search',
  //       ea: 'press enter',
  //       el: searchInputBox.value || '', // eg. "Chocolat"
  //       search_term: searchInputBox.value || '', // eg. "Chocolat"
  //       search_suggestion_number: hitsNumber <= 0 ? 0 : hitsNumber, // eg. "5"
  //     },
  //   });
  // };


  // const clickSuggestionDataLayerPush = (item: any, index: number) => {
  //   const category = item.querySelector('p')?.innerText.split(' / ')?.[0] || '';
  //   const subCategory = item.querySelector('p')?.innerText.split(' / ')?.[1] || '';
  //   const selectedIndex = index + 1;
  //   const searchSuggestion = item.querySelector('h2')?.innerText || '';

  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({
  //     event: 'click_search_suggestion',
  //     eventValues: {
  //       ec: 'search',
  //       ea: 'click search suggestion',
  //       el: `${category}_${subCategory}_${searchSuggestion}`, // eg. "recipe_cake_Chocolate Victoria Sponge cake"
  //       search_suggestion: searchSuggestion, // eg. "Chocolate Victoria Sponge cake"
  //       search_term: searchInputBox.value, // eg. "Chocolat"
  //       search_suggestion_category: category, // eg. "recipe"
  //       search_suggestion_subcategory: subCategory, // eg. "cake"
  //       search_suggestion_position: selectedIndex, // eg. "2"
  //       search_suggestion_number: hitsNumber <= 0 ? 0 : hitsNumber, // eg. "5"
  //     },
  //   });
  // };

  // useEffect(() => {
  //   document.querySelectorAll('.search-result-list-js').forEach((item, index) => {
  //     item.addEventListener('click', () => clickSuggestionDataLayerPush(item, index));
  //   });

  //   return () =>
  //     document.querySelectorAll('.search-result-list-js').forEach((item, index) => {
  //       item.removeEventListener('click', () => clickSuggestionDataLayerPush(item, index));
  //     });
  // }, [hitsNumber]);

  useEffect(() => {
    const handleSearch = async () => {
      if (debouncedSearchValue?.length > 0) {
        const searchData = await getPageSearch({ search: debouncedSearchValue, market: market as Markets, locale: locale as Locale, first: 3, skip: 0 });
        setHits(searchData.data);
        setTotal(searchData.totalCount);
      } else {
        setHits([]);
        setTotal(0);
      }
      setLoading(false);
    }
    handleSearch();
  }, [debouncedSearchValue]);

  const onClose = () => {
    setSearchValue('');
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }

  return (
    <SearchOverlayWrapper
      data-testid="searchOverlay"
      id="search-overlay"
      className={SearchOverlayWrapperStyle}
    >
      <Grid>
        <GridRow columns={16}>
          <GridItem colSpan={12} colStart={3} className="animate-slideDown">
            <GridContent data-test="card-carousel-grid-content">
              <SubHeading tag="p" type="lead" data-test="search-overlay-super">
                {getCopy('search', copy)}
              </SubHeading>
              <Heading tag="h2" type="md" data-test="search-overlay-title">
                {getCopy('search_overlay_heading', copy)}
              </Heading>
            </GridContent>
            <form action="POST" onSubmit={onSubmitHandler}>
              <div className="relative mt-30 mb-10">
                {searchValue.length > 0 && <CloseButton type="button" onClick={onClose}><CloseIcon /></CloseButton>}
                <SearchInput ref={inputRef} autoFocus placeholder={getCopy('search_input_placeholder', copy)} className="w-full form-input rounded-58 text-xxl md:text-2xxl" value={searchValue} onChange={e => {
                  setLoading(true);
                  setSearchValue(e.target.value);
                }}></SearchInput>
              </div>
              {!loading && hits?.length === 0 && debouncedSearchValue.length > 0 && <div className="mt-4 mb-8 text-center">
                <Title tag="p" type="lg" className="break-words mb-2">{getCopy('search_noresults_title', copy)}</Title>
                <Title tag="p" type="xs" className="break-words">{getCopy('search_noresults_text', copy).replace("{{search}}", debouncedSearchValue)}</Title>
              </div>}
              {hits?.length > 0 && <div className="mt-4 mb-8">{hits.slice(0, maxResults).map((hit: any) => <HitComponent key={hit.id} hit={hit} locale={locale} copy={copy} />)}</div>}
              {total > maxResults && <div className="flex justify-center mb-8">
                <CorporateButton
                  type="button"
                  buttonType='submit'
                  label={getCopy('show_all_results', copy)}
                  color="primary"
                /></div>}
            </form>
          </GridItem>
        </GridRow>
      </Grid>
    </SearchOverlayWrapper >
  );
};
