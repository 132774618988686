import { GetCorpGenericAccordionDocument, Scalars } from '../../generated/graphql';
import { client } from './client';
import { getGenericPageComponents } from './getGenericPageComponents';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getCorpAccordionData(locale: string, id: string, market: string | null) {
  let data: any;
  try {
    data = await client.query({
      query: GetCorpGenericAccordionDocument,
      variables: {
        locales: getLocalesForGraphQL(locale),
        where: {
          id: id as Scalars['ID'],
        },
      },
    });
    if (data && data.data && data.data.corpGenericAccordion) {
      // eslint-disable-next-line no-await-in-loop
      for (let j = 0; j < data.data.corpGenericAccordion.corpGenericAccordionItems.length; j += 1) {
        data.data.corpGenericAccordion.corpGenericAccordionItems[
          j
        ].genericAccordionItemBodyComponents = await getGenericPageComponents(
          data.data.corpGenericAccordion.corpGenericAccordionItems[j]
            .genericAccordionItemBodyComponents,
          locale,
          market,
        );
      }
      return data;
    }
  } catch (error: any) {
    console.error('Failed to fetch generic page with error:', error);
    return {};
  }
  return {};
}
