import tw from 'tailwind-styled-components';
import styled from 'styled-components';

export const DescriptionWrapper = styled.div`
   {
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
  }
`;

export const TitleWrapper = tw.div`
  pt-2.5
  lg:pb-7.5
  md:pb-5
  sm:pb-5
`;

export const RichTextWrapper = styled.div`
  a {
    text-decoration: underline;

    &:focus,
    &:hover {
      color: #010e54;
    }
  }
`;

export const Link = styled.a`

`;
Link.displayName = 'Link';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
ButtonContainer.displayName = 'ButtonContainer';