import tw from 'tailwind-styled-components';
import styled from 'styled-components';

export const DesktopLinks = tw.div`
  hidden
  md:block
`;

export const MobileLinks = tw.div`
  block
  md:hidden
`;

export const CorpSocialMediaLink = styled.a`
  svg {
    fill: white;
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const Container = tw.footer`
  mt-20
  relative
`;
export const FooterContent = tw.div<{ newsletter?: boolean }>`
  pb-4
  flex
  flex-grow
  pt-4
  lg:pt-14
  border-b
  border-white
  ${(p) => (p.newsletter ? `md:pb-20 lg:pt-44 md:pt-36 sm:pt-24` : '')}
  & > .accordion__item{
    border-color:#fff!important;
  }
`;
export const Svg = styled.svg`
  position: absolute;
  left: 0px;
  margin-top: -4.75rem;
`;

export const FLinks = tw.ul`
  pl-0
  m-0
`;
export const CountrySelectorWrap = tw.div`
  md:border-t
  border-white
  md:pt-20
  sm:pt-10;

`;
export const CountrySelectorInner = tw.div`
  md:w-3/5
  sm:w-full
  inline-block
`;
export const AppContainer = tw.div`
  md:w-2/5
  sm:w-full
  inline-block
  align-top
  sm:mt-10
  md:mt-0
`;

export const AppImg = tw.a`
  rounded
  h-9
  w-28
  mr-2
  bg-black
  inline-block
`;
export const LegalContainer = tw.div`
  border-t
  border-white
  md:pt-20
  sm:pt-10
  mt-5
  md:flex
  align-top
`;
export const Legal = tw.div`
  md:w-3/4
  sm:w-full
  md:text-right
  sm:text-left
  text-white
`;
export const LegalListWrap = tw.ul`
  m-0
  p-0
`;
export const LegalList = tw.li`
  md:inline-block
  md:w-auto
  sm:w-full
  mr-small
  md:last:mr-0
`;
export const SocialIcon = tw.img`
  inline-block
  mr-2
`;
