declare const window: any;
export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

export const pageview = (url: string) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'pageview',
      page: url,
    });
  }
};

export const contentGroups = (obj: any) => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      contentGroups: obj,
    });
  }
};
