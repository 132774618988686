import {
  GetShortHeroComponentDocument,
  GetShortHeroComponentQuery,
  GetShortHeroComponentQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getShortHeroComponent(id: string, locale: string) {
  return client.query<GetShortHeroComponentQuery, GetShortHeroComponentQueryVariables>({
    query: GetShortHeroComponentDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
