// TODO 2199 delete this file when CorpGridColumnComponent no longer in use in cms
import {
  GetCorpGridColumnComponentDocument,
  GetCorpGridColumnComponentQuery,
  GetCorpGridColumnComponentQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getCorpGridColumnComponent(id: string, locale: string) {
  return client.query<GetCorpGridColumnComponentQuery, GetCorpGridColumnComponentQueryVariables>({
    query: GetCorpGridColumnComponentDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
