import tw from 'tailwind-styled-components';
import { Text } from '../Typography/index';

export const IconWrapper = tw.div<{ $disabled: boolean; $hasLabel: boolean }>`
  flex
  ${(p) => (p.$hasLabel ? 'mr-2' : '')}
`;

export const ButtonText = tw(Text)<{ $color: string }>`
${(p) => p.$color}
  w-full
  rounded-full 
  flex 
  flex-row
  items-center 
  flex-nowrap 
  uppercase
  tracking-xl
  text-sm
  items-center
  justify-center
  space-x-3
`;

export const StyledButton = tw.button<{ $color: string; $className?: string }>`
${(p) => p.$color}
  flex
  group
  text-corporate-blue
  rounded-full 
  disabled:shadow-none 
  disabled:border-0
  disabled:cursor-not-allowed
  disabled:bg-opacity-100
  focus:border-2
  active:border-2
  ${(p) => p.className}
`;
