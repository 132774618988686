import {
  GetGenericDownloadComponentDocument,
  GetGenericDownloadComponentQuery,
  GetGenericDownloadComponentQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getGenericDownloadComponent(id: string, locale: string) {
  return client.query<GetGenericDownloadComponentQuery, GetGenericDownloadComponentQueryVariables>({
    query: GetGenericDownloadComponentDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
