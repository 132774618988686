import { Link } from "../../generated/graphql";

export enum BannerType {
  ALCOHOL_WARNING = 'alcohol_warning',
  RECALL_CAMPAIGN = 'recall_campaign'
}

export interface ICorpNotificationBannerProps {
  id?: string;
  title: string;
  description: string;
  link?: Pick<Link, "displayName" | "openInNewTab" | "url">;
  active: boolean;
  bannerType?: BannerType;
}
