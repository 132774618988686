import {
  GetCarouselComponentDocument,
  GetCarouselComponentQuery,
  GetCarouselComponentQueryVariables,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getCarouselComponent(id: string, locale: string) {
  return client.query<GetCarouselComponentQuery, GetCarouselComponentQueryVariables>({
    query: GetCarouselComponentDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
