import React, { useState, useEffect } from 'react';
import tw from 'tailwind-styled-components';
import { Accordion } from '../Accordion';
import { Title, Text } from '../Typography';
import { Grid, GridRow, GridItem } from '../Grid';
import { Link, StyledLink } from '../Link';
import { FooterNav } from '../../types/Footer';
import { CountrySelector } from '../CountrySelector';
import FadeInSection from '../FadeInSection';
import {
  Container,
  FooterContent,
  Svg,
  CountrySelectorWrap,
  CountrySelectorInner,
  LegalContainer,
  Legal,
  LegalListWrap,
  LegalList,
  CorpSocialMediaLink,
  FLinks,
} from './styles';
import Theme from '../../types/Theme';
import { ICorpSocialMediaFooter } from '../../types/CorpSocialMediaFooter';
import { Instagram, Facebook, Twitter, Youtube, LinkedIn, Xing } from '../Icons/icons';

const Copyright = tw(Title)`
  md:w-1/4
  sm:w-full
  sm:mb-2
`;
type FooterNavType = {
  cmsTitle?: string;
  footerNavType?: string;
  socialMediaLink?: boolean;
  id: string;
  links?: {
    displayName: string;
    url?: string;
    openInNewTab: boolean;
    id: string;
    internalLink?: Record<string, string>;
  }[];
};

const CorpSocialMedia = ({ socialMediaFooter }: { socialMediaFooter: ICorpSocialMediaFooter }) => {
  return (
    <div className="flex items-center md:justify-end">
      {socialMediaFooter?.twitterLink && (
        <div className="mr-5 last:mr-0">
          <CorpSocialMediaLink href={socialMediaFooter?.twitterLink} target="_blank">
            <Twitter />
          </CorpSocialMediaLink>
        </div>
      )}
      {socialMediaFooter?.facebookLink && (
        <div className="mr-5 last:mr-0">
          <CorpSocialMediaLink href={socialMediaFooter?.facebookLink} target="_blank">
            <Facebook />
          </CorpSocialMediaLink>
        </div>
      )}
      {socialMediaFooter?.instagramLink && (
        <div className="mr-5 last:mr-0">
          <CorpSocialMediaLink href={socialMediaFooter?.instagramLink} target="_blank">
            <Instagram />
          </CorpSocialMediaLink>
        </div>
      )}
      {socialMediaFooter?.linkedinLink && (
        <div className="mr-5 last:mr-0">
          <CorpSocialMediaLink href={socialMediaFooter?.linkedinLink} target="_blank">
            <LinkedIn />
          </CorpSocialMediaLink>
        </div>
      )}
      {socialMediaFooter?.youtubeLink && (
        <div className="mr-5 last:mr-0">
          <CorpSocialMediaLink href={socialMediaFooter?.youtubeLink} target="_blank">
            <Youtube />
          </CorpSocialMediaLink>
        </div>
      )}
      {socialMediaFooter?.xingLink && (
        <div className="mr-5 last:mr-0">
          <CorpSocialMediaLink href={socialMediaFooter?.xingLink} target="_blank">
            <Xing />
          </CorpSocialMediaLink>
        </div>
      )}
    </div>
  );
};

type Links = {
  displayName: string;
  url?: string;
  openInNewTab: boolean;
  id: string;
  internalLink?: Record<string, string>;
};

export const CorpFooter = ({
  theme = 'Default',
  currentMarket = 'global',
  footerData,
  socialMediaFooter,
  countrySelectorData,
  onMarketChange,
}: {
  theme?: Theme;
  footerData: FooterNav;
  socialMediaFooter: ICorpSocialMediaFooter;
  countrySelectorData: any;
  currentMarket: string;
  onMarketChange(market: string): void;
}) => {
  const footerNav = footerData?.footerNavigations;
  const privacySettingsTitle = footerData?.privacySettingsTitle;
  const legal = footerNav?.find((item: any) => item.footerNavType === 'LegalSection');
  const mainNav = footerNav?.filter((mainitem: FooterNavType) => {
    let mainitems;
    if (mainitem.footerNavType === 'MainNav') {
      mainitems = mainitem;
    }
    return mainitems;
  });
  const listTemplate = (flinks: FooterNavType) => {
    const mainNavlist = flinks?.links?.map((fNavLink: Links) => {
      let link = fNavLink?.url || '/';
      if (link.indexOf('http') === -1 && link.indexOf('/') !== 0) {
        link = `/${link}`;
      }
      return (
        <li className="mb-5" key={fNavLink.id}>
          <Text tag="p" type="md">
            <Link theme={theme} href={link} target={fNavLink.openInNewTab ? '_blank' : '_self'}>
              {fNavLink.displayName}
            </Link>
          </Text>
        </li>
      );
    });
    return mainNavlist;
  };

  const mobileNavData = footerNav
    ?.filter((navlist: FooterNavType) => {
      let navlists;
      if (navlist.footerNavType === 'MainNav') {
        navlists = navlist;
      }
      return navlists;
    })
    .map((navlistlink: FooterNavType) => {
      const bodyTempl = navlistlink?.links?.map((bodyLink: any) => (
        <Text tag="p" type="md" key={bodyLink.id}>
          <span className="block mb-2.5">
            <Link href={bodyLink.url} target={bodyLink.openInNewTab && '_blank'}>
              {bodyLink.displayName}
            </Link>
          </span>
        </Text>
      ));
      return {
        title: (
          <Title tag="h4" type="xs" className={'text-white'} key={navlistlink.id}>
            {navlistlink.cmsTitle}
          </Title>
        ),
        body: (
          <div className="mb-5" key={navlistlink.id}>
            {bodyTempl}
          </div>
        ),
      };
    });

  const [screenWidth, setScreenWidth] = useState<number>(0);
  const screenSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    screenSize();
    window.addEventListener('load', screenSize);
    window.addEventListener('resize', screenSize);
    return () => window.removeEventListener('resize', screenSize);
  }, []);
  return (
    <FadeInSection delay="50ms">
      <Container className={'bg-corporate-blue'} data-test="footer" data-print="hide">
        <Svg
          width="100%"
          height="80"
          viewBox="0 0 1290 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 168C7.16344 168 0 160.837 0 152V43.5099C0 43.5099 296.521 -3.02334e-05 647.688 0C998.854 3.02336e-05 1290 43.51 1290 43.51V152C1290 160.837 1282.84 168 1274 168H16Z"
            fill={theme === 'Corporate' ? '#010E54' : '#010E54'}
          />
        </Svg>
        <FooterContent>
          <Grid>
            {screenWidth <= 768 ? (
              <div className="mb-10">
                <Accordion
                  data-test="footer-accordion-under-768"
                  name=""
                  borderColor="#fff"
                  className="text-white accordion__button"
                  data={mobileNavData}
                  collapsed
                />
              </div>
            ) : (
              <div className="px-small">
                <GridRow columns={16} className={'md:mb-8'}>
                  {mainNav?.map((links: FooterNavType) => (
                    <GridItem colSpan={4} key={`title=${links.id}`}>
                      <Title tag="h3" type="xs" className={'text-white'}>
                        {links.cmsTitle}
                      </Title>
                    </GridItem>
                  ))}
                </GridRow>
                <GridRow columns={16} className={'md:mb-16'}>
                  {mainNav?.map((flinks: FooterNavType) => (
                    <GridItem colSpan={4} key={`body=${flinks.id}`}>
                      <FLinks data-test="footer-flinks">{listTemplate(flinks)}</FLinks>
                    </GridItem>
                  ))}
                </GridRow>
              </div>
            )}
            <div className="px-small">
              <GridRow columns={16}>
                <GridItem colSpan={16}>
                  <CountrySelectorWrap className={'border-white'}>
                    <CountrySelectorInner>
                      <CountrySelector
                        onChange={onMarketChange}
                        theme={theme}
                        data={countrySelectorData}
                        initialCountryCode={currentMarket}
                      />
                    </CountrySelectorInner>
                  </CountrySelectorWrap>
                </GridItem>
              </GridRow>
            </div>
            <div className="px-small">
              <div className="grid m-0 lg:grid-cols-16 md:grid-cols-16 sm:grid-cols-4 gap-20 mb-0 mt-14 md: mt-24">
                <GridItem colSpan={16}>
                  <CorpSocialMedia socialMediaFooter={socialMediaFooter} />
                </GridItem>
              </div>
            </div>
            <div className="px-small">
              <GridRow columns={16}>
                <GridItem colSpan={16}>
                  <LegalContainer data-test="footer-legal-container">
                    {legal && (
                      <>
                        <Copyright tag="p" type="xxxs" className={'text-white'}>
                          {legal.cmsTitle}
                        </Copyright>
                        <Legal data-test="footer-legal">
                          <LegalListWrap data-test="footer-legal-list-wrap">
                            <LegalList data-test="footer-legal-list">
                              <Text tag="p" type="md">
                                <StyledLink>
                                  <a
                                    href="#"
                                    onClick={() => (window as any)?.UC_UI?.showSecondLayer()}
                                  >
                                    {privacySettingsTitle || 'Privacy Settings'}
                                  </a>
                                </StyledLink>
                              </Text>
                            </LegalList>
                            {legal?.links?.map((legallinks: any) => (
                              <LegalList
                                data-test="footer-legal-list"
                                key={`legal-${legallinks.id}`}
                              >
                                <Text tag="p" type="md">
                                  <Link
                                    data-test="footer-legal-links"
                                    href={legallinks?.url || '/'}
                                    target={legallinks.openInNewTab && '_blank'}
                                  >
                                    {legallinks.displayName}
                                  </Link>
                                </Text>
                              </LegalList>
                            ))}
                          </LegalListWrap>
                        </Legal>
                      </>
                    )}
                  </LegalContainer>
                </GridItem>
              </GridRow>
            </div>
          </Grid>
        </FooterContent>
      </Container>
    </FadeInSection>
  );
};
