import {
  CorpCardCarouselType,
  CorpStoryTagEnum,
  GetCorpCardCarouselDocument,
  GetCorpCardCarouselQuery,
  GetCorpCardCarouselQueryVariables,
  GetPressReleasesFilteredDocument,
  GetPressReleasesFilteredQuery,
  GetPressReleasesFilteredQueryVariables,
  GetStoriesFilteredDocument,
  GetStoriesFilteredQuery,
  GetStoriesFilteredQueryVariables,
  Locale,
  Markets,
  Scalars,
} from 'generated/graphql';
import { client } from './client';
import { findInternalLink } from './getGenericPageComponents';
import { getLocalesForGraphQL } from '../utils/helpers';

/**
 * Get data for corp card carousel as well as the belonging cards
 */
export const getCorpCarouselComponentAndData = async (
  component: any,
  locale: string,
  market: string | null,
) => {
  let componentData: any = {};
  // we have to get the component carousel type here, so that we know which press releases or stories to pull.
  let cardData: any[] = [];
  const carouselType: CorpCardCarouselType = component?.corpCarouselType;
  // we have to pass the global card type to get the correct translations inside the cards
  let type: any = 'press';
  // now get card data by carousel type
  if (carouselType === 'pressAll') {
    // get press releases
    const currCards = await getCorpCardCarouselTypePressAll(locale, market);

    if (currCards) {
      // for some reason graphcms is returning an object rather than an array here
      const keys: any[] = Object.keys(currCards?.data?.pressReleases);
      if (keys.length > 0) {
        for (const key of keys) {
          cardData.push(currCards?.data?.pressReleases[key]);
        }
        // @TODO Internal Links
      }
    }
  } else {
    // get stories
    type = 'story';

    const tags = [];

    switch (carouselType) {
      case 'storiesSustainability':
        tags.push(
          CorpStoryTagEnum.sustainability,
          CorpStoryTagEnum.sustainabilityCompany,
          CorpStoryTagEnum.sustainabilityFood,
          CorpStoryTagEnum.sustainabilityWorld,
        );
        break;
      case 'storiesCompany':
        tags.push(CorpStoryTagEnum.sustainabilityCompany);
        break;
      case 'storiesFood':
        tags.push(CorpStoryTagEnum.sustainabilityFood);
        break;
      case 'storiesWorld':
        tags.push(CorpStoryTagEnum.sustainabilityWorld);
        break;
      default:
        break;
    }

    const currCards = await getCorpCardCarouselTypeStories(locale, tags, market ?? Markets.global);
    if (currCards && currCards?.data && currCards.data.stories.length > 0) {
      // for some reason graphcms is returning an object rather than an array here
      const keys: any[] = Object.keys(currCards?.data?.stories);
      if (keys.length > 0) {
        for (const key of keys) {
          cardData.push(currCards?.data?.stories[key]);
        }
        // @TODO Internal Links
      }
    }
  }

  // do not render if no cards found
  if (cardData.length === 0) {
    return {};
  } else {
    // update urls
    const updatedCards = [];
    for (const card of cardData) {
      // get correct market url
      const currMarket = !market || market === 'global' ? '/' : `/${market}/`;
      card.slug =
        type === 'story' ? `${currMarket}stories/${card.slug}` : `${currMarket}press/${card.slug}`;
      updatedCards.push(card);
    }
    cardData = updatedCards;
  }

  componentData = await getCorpCardCarousel(component.id, locale);
  if (componentData?.data?.corpCardCarousel) {
    componentData.data.corpCardCarousel.type = type;
  }

  componentData = { ...componentData, type };
  // @TODO: make getGenericPageComponents more flexible
  if (componentData?.data) {
    findInternalLink(componentData.data[Object.keys(componentData.data)[0]], locale);
  }
  const corpCardCarouselLowerCaseFirstChar =
    component.__typename.charAt(0).toLowerCase() + component.__typename.substring(1);
  return { ...componentData?.data[corpCardCarouselLowerCaseFirstChar], cardData };
};

// get press releases
export async function getCorpCardCarouselTypePressAll(locale: string, market: string | null) {
  const where = {
    market: market as Markets,
  };
  return client.query<GetPressReleasesFilteredQuery, GetPressReleasesFilteredQueryVariables>({
    query: GetPressReleasesFilteredDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      first: 10,
      where,
    },
  });
}

// get stories
export async function getCorpCardCarouselTypeStories(
  locale: string,
  tags: CorpStoryTagEnum[],
  market: string,
) {
  const where: any = {
    markets_contains_some: [market],
  };
  if (tags && tags.length > 0) {
    where.storyTagEnum_in = tags;
  }

  return client.query<GetStoriesFilteredQuery, GetStoriesFilteredQueryVariables>({
    query: GetStoriesFilteredDocument,
    variables: {
      locales: [locale as Locale],
      first: 10,
      where,
    },
  });
}

export async function getCorpCardCarousel(id: string, locale: string) {
  return client.query<GetCorpCardCarouselQuery, GetCorpCardCarouselQueryVariables>({
    query: GetCorpCardCarouselDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
